import axios from "axios";
import {
  Toast
} from "vant";
import {
  getToken,removeToken
} from "@/utils/auth";

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 5000 // 超时时长
});

//请求拦截
service.interceptors.request.use(
  config => {
    let token = getToken();
    if (token) {
      //如果有token，插入公共token
      config.headers["token"] = token;
    }
    return config;
  },
  error => {
    //请求错误时处理
    console.log(error);
    return Promise.reject(error);
  }
);

//响应拦截
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data;
    //处理公共返回参数
    if (res.code === 101) {
      //重新登录
      Toast("您尚未登录或登录超时");
      //清空cookie
      removeToken();
      location.reload();

      return Promise.reject(new Error(res.message || "Error"));
    } else {
      return res;
    }
  },
  (error) => {
    console.log("请求失败");
    console.log(error);
    Toast("请求失败：" + error.message);
    return Promise.reject(error);
  }
);

export default service;